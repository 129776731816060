import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import WideContainer from "rxs/components/WideContainer";
import "./Home.css";

import logoText from "../images/logo-omen-text.svg";
import nemoLogo from "../images/Nemo_NuWht-min.png";
import ueLogo from "../images/ue-logo.png";
import engines from "../images/engines-min.png";
import servicesLogos from "../images/services-logos-min.png";
import chairs from "../images/chairs.jpg";

import omen from "../images/AOne_OOS-min.png";
import Button from "aone/components/Button";
import useVisible from "rxs/hooks/useVisible";

import video1 from "../videos/Omen_01.mp4";
import video2 from "../videos/Omen_02.mp4";
import video3 from "../videos/Omen_03.mp4";
import Dots from "aone/components/Dots";
import Banner from "aone/components/Banner";
import { useHref, useNavigate } from "react-router-dom";
import useMobile from "rxs/hooks/useMobile";

const videos = [video1, video2, video3];

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mobile = useMobile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const videoRef = useRef();
  const [videoIndex, setVideoIndex] = useState(0);
  const [videoVisible, setVideoVisible] = useState(false);

  useEffect(() => {
    videoRef.current?.load();
    let videoTimeout = setTimeout(() => {
      setVideoIndex(p => (p + 1) % videos.length);
    }, 10000);
    return () => clearTimeout(videoTimeout);
  }, [videoIndex]);

  const [omenBannerImage, omenBannerRef] = useVisible({
    once: true,
    threshold: 0.9,
  });

  const handleVideoLoaded = () => {
    setVideoVisible(true);
    videoRef.current.play();
  };

  return (
    <>
      <div
        className={`cl-white f-center header home ${mobile ? "" : "full"}`}
        style={{
          minHeight: "600px",
          height: "calc(100vh - 100px)",
        }}
      >
        <video
          ref={videoRef}
          className="p-absolute mnw-100 mnh-100 zi-0"
          loop
          muted
          src={videos[videoIndex]}
          type="video/mp4"
          onCanPlayThrough={handleVideoLoaded}
          style={{ visibility: videoVisible ? "visible" : "hidden" }}
        />
      </div>
      <WideContainer className="py-64" maxWidth="700px">
        <div className="px-16" style={{ lineHeight: "200%" }}>
          {t("companyInfo")}
        </div>
      </WideContainer>

      <div className="pt-256 pb-128 p-relative">
        <Dots
          className="p-absolute t-0 l-0 w-100 f-center"
          size={6}
          amount={16}
        />
        <Banner
          ref={omenBannerRef}
          TextComponent={
            <>
              <img
                className="py-32"
                src={logoText}
                alt="Omen of Sorrow - Title"
              />
              <div
                className="ta-justify cl-white"
                style={{
                  lineHeight: "150%",
                }}
              >
                {t("omenInfo")}
              </div>
              <Button
                className="my-32 fw-bold "
                as="a"
                href="https://omen.aonegames.com"
                target="_blank"
                rel="noreferrer"
              >
                {t("startFighting")}
              </Button>
            </>
          }
          ImageComponent={
            <img
              className={mobile ? "" : "absolute"}
              src={omen}
              alt="Omen of Sorrow - Game"
              style={{
                width: mobile ? "90%" : "110%",
                clipPath: mobile
                  ? ""
                  : `polygon(
                  0 0,
                  ${omenBannerImage ? 118.4 : 0}% 0,
                  ${omenBannerImage ? 100 : -18.4}% 100%,
                  0 100%
                )`,
                right: 0,
                transition: "all 1s",
              }}
            />
          }
          textContainerClassName="bg-omen"
          noImageTransition={!mobile}
        />
      </div>

      <div className="pt-256 pb-128 p-relative">
        <Dots
          className="p-absolute t-0 l-0 w-100 f-center"
          size={6}
          amount={16}
        />
        <Banner
          TextComponent={
            <>
              <div className="pb-8 cl-white f-row ai-center jc-space-between">
                <img
                  className="mnw-0"
                  src={nemoLogo}
                  alt="Nemo - Logo"
                  style={{
                    objectFit: "contain",
                    height: 128,
                  }}
                />

                <img
                  src={ueLogo}
                  alt="Nemo - Logo"
                  style={{
                    objectFit: "contain",
                    height: 64,
                  }}
                />
              </div>
              <div
                className="ta-justify cl-white"
                style={{
                  lineHeight: "150%",
                }}
              >
                {t("nemoInfo")}
              </div>
              <Button
                className="my-32 fw-bold "
                as="a"
                href={useHref("nemo")}
                onClick={e => {
                  e.preventDefault();
                  navigate("nemo");
                }}
              >
                {t("learnMore")}
              </Button>
            </>
          }
          ImageComponent={
            <img
              src={engines}
              alt="Omen of Sorrow - Game"
              style={{
                objectFit: "contain",
                width: mobile ? "90%" : "100%",

                maxHeight: 300,
              }}
            />
          }
          textContainerClassName="bg-nemo"
          right
        />
      </div>

      <div className="pt-256 pb-128 p-relative">
        <Dots
          className="p-absolute t-0 l-0 w-100 f-center"
          size={6}
          amount={16}
        />
        <Banner
          TextComponent={
            <>
              <div className="pb-8 cl-white f-row ai-center jc-space-between">
                <div className="tt-uppercase fs-32 fw-bold  pt-32 pb-16">
                  {t("services")}
                </div>
              </div>
              <div
                className="ta-justify cl-white"
                style={{
                  lineHeight: "150%",
                }}
              >
                {t("servicesInfo")}
              </div>
              <Button
                className="my-32 fw-bold "
                as="a"
                href={useHref("services")}
                onClick={e => {
                  e.preventDefault();
                  navigate("services");
                }}
              >
                {t("checkOut")}
              </Button>
            </>
          }
          ImageComponent={
            <img
              src={servicesLogos}
              alt="Omen of Sorrow - Game"
              style={{
                objectFit: "contain",
                width: mobile ? "90%" : "100%",
                maxHeight: 300,
              }}
            />
          }
          textContainerClassName="bg-services"
        />
      </div>

      <div className="pt-256  p-relative">
        <Dots
          className="p-absolute t-0 l-0 w-100 f-center"
          size={6}
          amount={16}
        />
        <Banner
          TextComponent={
            <>
              <div className="pb-8 cl-white f-row ai-center jc-space-between">
                <div className="tt-uppercase fs-32 fw-bold  pt-32 pb-16">
                  {t("business")}
                </div>
              </div>
              <div
                className="ta-justify cl-white"
                style={{
                  lineHeight: "150%",
                }}
              >
                {t("businessInfo")}
              </div>
              <Button
                className="my-32 fw-bold "
                as="a"
                href={useHref("clients")}
                onClick={e => {
                  e.preventDefault();
                  navigate("clients");
                }}
              >
                {t("ourClients")}
              </Button>
            </>
          }
          ImageComponent={
            <img
              className="p-16"
              src={chairs}
              alt="Omen of Sorrow - Game"
              style={{
                objectFit: "contain",
                width: mobile ? "90%" : "100%",
                maxHeight: 300,
              }}
            />
          }
          textContainerClassName="bg-business"
          right
        />
      </div>
    </>
  );
};

export default Home;
